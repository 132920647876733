<template>
  <div class="dg-ppm-files">
    <DgFileUploader
      :label="$t('ppm.form.privacy_contract.label')"
      :placeholder="$t('ppm.form.privacy_contract.placeholder')"
      :selectFile="$t('ppm.form.privacy_contract.select_file_text')"
      :helperText="$t('ppm.form.privacy_contract.helper_text')"
      variant="simple"
      :accepts="['image', 'doc']"
      :maxSize="52428800"
      :required="isContractRequired"
      @change="files => $emit('upload', 'privacy_contract', files)"
      @delete="(index, fileObj) => $emit('remove', 'privacy_contract', index, fileObj)"
      @error="onFileUploadError"
    />
    <DgFileUploader
      :label="$t('ppm.form.toms.label')"
      :placeholder="$t('ppm.form.toms.placeholder')"
      :selectFile="$t('ppm.form.toms.select_file_text')"
      :helperText="$t('ppm.form.toms.helper_text')"
      variant="simple"
      :accepts="['image', 'doc']"
      :maxSize="10485760"
      @change="files => $emit('upload', 'toms', files)"
      @delete="(index, fileObj) => $emit('remove', 'toms', index, fileObj)"
      @error="onFileUploadError"
    />
    <DgFileUploader
      :label="$t('ppm.form.main_contract.label')"
      :placeholder="$t('ppm.form.main_contract.placeholder')"
      :selectFile="$t('ppm.form.main_contract.select_file_text')"
      :helperText="$t('ppm.form.main_contract.helper_text')"
      variant="simple"
      :accepts="['image', 'doc']"
      :maxSize="10485760"
      @change="files => $emit('upload', 'main_contract', files)"
      @delete="(index, fileObj) => $emit('remove', 'main_contract', index, fileObj)"
      @error="onFileUploadError"
    />
    <DgFileUploader
      :label="$t('ppm.form.additional_attachments.label')"
      :placeholder="$t('ppm.form.additional_attachments.placeholder')"
      :selectFile="$t('ppm.form.additional_attachments.select_file_text')"
      :helperText="$t('ppm.form.additional_attachments.helper_text')"
      variant="simple"
      :accepts="['image', 'doc']"
      :maxSize="10485760"
      :maxFiles="5"
      @change="files => $emit('upload', 'additional_attachments', files)"
      @delete="(index, fileObj) => $emit('remove', 'additional_attachments', index, fileObj)"
      @error="onFileUploadError"
    />
  </div>
</template>

<script>
export default {
  props: {
    isContractRequired: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onFileUploadError(type) {
      switch (type) {
        case "large_file_error":
          this.$dgNotification.show({
            variant: "bold",
            type: "warning",
            title: this.$t("ppm.form.fileErrors.largeFileError"),
          });
          break;
        /**
         * Will extend this further to handle other errors
         *
         * other errors are `invalid_file_type`, `max_files_error`
         */
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.dg-ppm-files {
  .dg-fileuploader {
    margin-top: 16px;
  }

  .dg-fileuploader__input-container {
    max-width: 420px;
  }
}
</style>
