<template>
  <dg-input v-bind="$attrs" v-on="$listeners" @input="filter" />
</template>

<script>
export default {
  methods: {
    filter(evt) {
      // Currently this input is only used for phone numbers, which the backend validates to only include digits and
      // doesn't allow things like spaces, +, -, / and other characters that are commonly used in phone numbers.
      // We need to use the `input` event to also handle invalid values being pasted rather than entered directly.
      const cleanedValue = evt.target.value.replaceAll(/[^\d]/g, "");

      if (cleanedValue !== evt.target.value) {
        evt.target.value = cleanedValue;
      }
    },
  },
};
</script>
