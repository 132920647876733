<template>
  <form class="dg-ppm__form" @submit.prevent="submitForm" autocomplete="off">
    <template v-if="isModeNormal">
      <h2 class="dg-ppm__form__title">{{ $t("ppm.form.title") }}</h2>
      <hr class="dg-ppm__form__break top" />
      <div class="dg-ppm__form__group">
        <div class="dg-ppm__form__input half">
          <dg-input
            v-bind="fieldOptions('typeOfContract')"
            inputType="select"
            @change="
              e => {
                onFieldChange(e, 'typeOfContract');
                resetYourRole();
              }
            "
          />
        </div>
      </div>
      <div class="dg-ppm__form__group">
        <div class="dg-ppm__form__input half">
          <dg-input
            v-bind="fieldOptions('yourRole')"
            :selectOptions="yourRoleOptions"
            :disabled="isRoleDisabled"
            inputType="select"
            @change="e => onFieldChange(e, 'yourRole')"
          />
        </div>
      </div>
    </template>
    <div class="dg-ppm__form__group">
      <div class="dg-ppm__form__input">
        <dg-input v-bind="fieldOptions('partnerName')" @input="e => onFieldChange(e, 'partnerName')" />
      </div>
    </div>
    <h3 class="dg-ppm__form__sub-title">{{ $t("ppm.form.sections.partner_contact") }}</h3>
    <div class="dg-ppm__form__group">
      <div class="dg-ppm__form__input half">
        <dg-input
          v-bind="fieldOptions('salutation')"
          @change="e => onFieldChange(e, 'salutation')"
          inputType="select"
        />
      </div>
    </div>
    <div class="dg-ppm__form__group">
      <div class="dg-ppm__form__input half">
        <dg-input v-bind="fieldOptions('firstName')" @input="e => onFieldChange(e, 'firstName')" />
      </div>
      <div class="dg-ppm__form__input half">
        <dg-input v-bind="fieldOptions('lastName')" @input="e => onFieldChange(e, 'lastName')" />
      </div>
    </div>
    <div class="dg-ppm__form__group">
      <div class="dg-ppm__form__input country_code">
        <dg-input
          inputType="select"
          :selectOptions="countryCodeOptions"
          v-bind="fieldOptions('country_code')"
          @input="e => onFieldChange(e, 'country_code')"
        />
      </div>
      <div class="dg-ppm__form__input">
        <PPMNumberInput v-bind="fieldOptions('phone')" @input="e => onFieldChange(e, 'phone')" />
      </div>
    </div>
    <div class="dg-ppm__form__group">
      <div class="dg-ppm__form__input">
        <dg-input
          v-bind="fieldOptions('email')"
          @input="
            e => {
              onFieldChange(e, 'email');
            }
          "
        />
      </div>
    </div>
    <h3 class="dg-ppm__form__sub-title">{{ $t("ppm.form.sections.partner_address") }}</h3>
    <div class="dg-ppm__form__group">
      <div class="dg-ppm__form__input">
        <dg-input v-bind="fieldOptions('street')" @input="e => onFieldChange(e, 'street')" />
      </div>
      <div class="dg-ppm__form__input house-number">
        <dg-input v-bind="fieldOptions('houseNumber')" @input="e => onFieldChange(e, 'houseNumber')" />
      </div>
    </div>
    <div class="dg-ppm__form__group">
      <div class="dg-ppm__form__input zip-code">
        <dg-input v-bind="fieldOptions('zipCode')" @input="e => onFieldChange(e, 'zipCode')" />
      </div>
      <div class="dg-ppm__form__input">
        <dg-input v-bind="fieldOptions('city')" @input="e => onFieldChange(e, 'city')" />
      </div>
    </div>
    <div class="dg-ppm__form__group">
      <div class="dg-ppm__form__input">
        <dg-input v-bind="fieldOptions('country')" @change="e => onFieldChange(e, 'country')" inputType="select" />
      </div>
    </div>
    <template v-if="hasFiles">
      <h3 class="dg-ppm__form__sub-title">{{ $t("ppm.form.sections.avv_documents") }}</h3>
      <PPMFilesUpload @upload="handleFileUploads" @remove="handleFileRemoval" :isContractRequired="isModeNormal" />
    </template>
    <div v-show="isModeNormal">
      <hr class="dg-ppm__form__break bottom" />
      <div class="dg-ppm__form__submit">
        <dg-button type="submit" :loading="isCreating">{{ $t("ppm.form.submit") }}</dg-button>
      </div>
    </div>
  </form>
</template>

<script>
import validator from "email-validator";

import PPMNumberInput from "./PPMNumberInput.vue";
import PPMFilesUpload from "../PPMFilesUpload.vue";
import countryList from "../../store/countries.json";

const contractRouteMap = {
  PPMReview: "review_contract",
  PPMCreate: "create_contract",
  PPMUpload: "import_contract",
};

export default {
  name: "PPMUpload",
  components: {
    PPMNumberInput,
    PPMFilesUpload,
  },
  props: {
    hasFiles: {
      type: Boolean,
      default: true,
    },
    /**
     * This prop defines when this component is rendered for sub-contract creation
     */
    mode: {
      type: String,
      default: "normal",
      validator(modeVal) {
        return ["normal", "subcontract"].includes(modeVal);
      },
    },
    /**
     * This prop is used when passing information to the backend API
     */
    subcontractDetails: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      fields: {
        typeOfContract: {
          label: this.$t("ppm.form.type_of_contract.label"),
          selectOptions: [
            {
              name: this.$t("ppm.form.type_of_contract.placeholder"),
              value: "",
              attrs: {
                selected: true,
                disabled: true,
              },
            },
            {
              name: this.$t("ppm.form.type_of_contract.dpa"),
              value: "dpa",
            },
            {
              name: this.$t("ppm.form.type_of_contract.jca"),
              value: "jca",
            },
          ],
          attrs: {
            name: "type-of-contract",
            required: true,
            value: "",
          },
          error: false,
          help: "",
          tKey: "type_of_contract",
        },
        yourRole: {
          label: this.$t("ppm.form.roles.label"),
          attrs: {
            name: "role-of-contract",
            required: true,
            value: "",
          },
          error: false,
          help: "",
          tKey: "roles",
        },
        partnerName: {
          label: this.$t("ppm.form.contract_partner_name.label"),
          attrs: {
            name: "partner-name",
            required: true,
            value: "",
          },
          error: false,
          help: "",
          tKey: "contract_partner_name",
        },
        salutation: {
          label: this.$t("ppm.form.salutation.label"),
          selectOptions: [
            {
              name: this.$t("ppm.form.salutation.placeholder"),
              value: "",
              attrs: {
                selected: true,
                disabled: true,
              },
            },
            {
              name: this.$t("ppm.form.salutation.male"),
              value: "male",
            },
            {
              name: this.$t("ppm.form.salutation.female"),
              value: "female",
            },
          ],
          attrs: {
            name: "salutation",
            required: true,
            value: "",
          },
          error: false,
          help: "",
          tKey: "salutation",
        },
        firstName: {
          label: this.$t("ppm.form.first_name.label"),
          attrs: {
            name: "first-name",
            required: true,
            value: "",
          },
          error: false,
          help: "",
          tKey: "first_name",
        },
        lastName: {
          label: this.$t("ppm.form.last_name.label"),
          attrs: {
            name: "last-name",
            required: true,
            value: "",
          },
          error: false,
          help: "",
          tKey: "last_name",
        },
        country_code: {
          label: this.$t("ppm.form.country_code.label"),
          attrs: {
            name: "country_code",
            required: true,
            value: "",
          },
          error: false,
          help: "",
          tKey: "country_code",
        },
        phone: {
          label: this.$t("ppm.form.phone.label"),
          attrs: {
            name: "phone",
            required: true,
            placeholder: this.$t("ppm.form.phone.placeholder"),
            value: "",
            maxlength: "20",
          },
          error: false,
          help: "",
          tKey: "phone",
        },
        email: {
          label: this.$t("ppm.form.email.label"),
          attrs: {
            name: "email",
            required: true,
            placeholder: this.$t("ppm.form.email.placeholder"),
            value: "",
            type: "email",
          },
          error: false,
          help: "",
          tKey: "email",
        },
        street: {
          label: this.$t("ppm.form.street.label"),
          attrs: {
            name: "street-name",
            required: true,
            value: "",
          },
          error: false,
          help: "",
          tKey: "street",
        },
        houseNumber: {
          label: this.$t("ppm.form.house_number.label"),
          attrs: {
            name: "house-number",
            required: true,
            value: "",
            maxlength: "10",
          },
          error: false,
          help: "",
          tKey: "house_number",
        },
        zipCode: {
          label: this.$t("ppm.form.zip_code.label"),
          attrs: {
            name: "zip-code",
            required: true,
            value: "",
            maxlength: "15",
          },
          error: false,
          help: "",
          tKey: "zip_code",
        },
        city: {
          label: this.$t("ppm.form.city.label"),
          attrs: {
            name: "city",
            required: true,
            value: "",
          },
          error: false,
          help: "",
          tKey: "city",
        },
        country: {
          label: this.$t("ppm.form.country.label"),
          selectOptions: [
            {
              name: this.$t("ppm.form.country.placeholder"),
              value: "",
              attrs: {
                selected: true,
                disabled: true,
              },
            },
            ...countryList,
          ],
          attrs: {
            name: "country",
            required: true,
            value: "",
          },
          error: false,
          help: "",
          tKey: "country",
        },
      },
      files: {
        privacy_contract: [],
        toms: [],
        main_contract: [],
        additional_attachments: [],
      },
      isCreating: false,
    };
  },
  computed: {
    isModeNormal() {
      return this.mode === "normal";
    },
    isModeSubcontract() {
      return this.mode === "subcontract";
    },
    yourRoleOptions() {
      const typeOfContract = this.fields.typeOfContract.attrs.value;
      let roleOptions = [
        {
          name: this.$t("ppm.form.roles.placeholder"),
          value: "",
          attrs: {
            selected: true,
            disabled: true,
          },
        },
      ];
      if (typeOfContract === "dpa") {
        roleOptions = [
          ...roleOptions,
          {
            name: this.$t("ppm.form.roles.controller"),
            value: "controller",
          },
          {
            name: this.$t("ppm.form.roles.processor"),
            value: "processor",
          },
        ];
      } else if (typeOfContract === "jca") {
        roleOptions = [
          {
            name: this.$t("ppm.form.roles.controller"),
            value: "controller",
            attrs: {
              selected: true,
              disabled: true,
            },
          },
        ];
      }
      return roleOptions;
    },
    countryCodeOptions() {
      return [
        {
          name: this.$t("ppm.form.country_code.placeholder"),
          value: "",
          attrs: {
            selected: true,
            disabled: true,
          },
        },
        ...countryList.map(({ name, dial_code }) => {
          return { name: `${dial_code} ${name} `, value: dial_code };
        }),
      ];
    },
    isRoleDisabled() {
      return !Boolean(this.fields.typeOfContract.attrs.value);
    },
  },
  methods: {
    fieldOptions(key) {
      return {
        ...this.fields[key],
        "data-testid": this.fields[key]["tKey"],
      };
    },
    onFieldChange(e, fieldKey) {
      this.fields[fieldKey].attrs.value = e.target.value;
      this.fields[fieldKey].help = "";
      this.fields[fieldKey].error = false;
      this.isFieldValid(e.target.value, fieldKey, this.fields[fieldKey].attrs.type);
    },
    resetYourRole() {
      if (this.fields.typeOfContract.attrs.value === "jca") {
        this.fields.yourRole.attrs.value = "controller";
      }
      this.fields.yourRole.help = "";
      this.fields.yourRole.error = false;
    },
    handleFileUploads(documentKey, files) {
      this.files[documentKey] = [...files];
    },
    handleFileRemoval(documentKey, fileIndex) {
      this.files[documentKey].splice(fileIndex, 1);
    },
    isFieldValid(fieldValue, fieldKey, fieldType = "input") {
      let errorText = "";
      let isFieldValid = Boolean(fieldValue);
      if (!isFieldValid) {
        errorText = this.$t(`ppm.form.${this.fields[fieldKey].tKey}.error_text`);
      }
      if (isFieldValid && fieldType === "email") {
        isFieldValid = validator.validate(fieldValue);
        if (!isFieldValid) {
          errorText = this.$t(`ppm.form.${this.fields[fieldKey].tKey}.invalid_email`);
        }
      }
      this.fields[fieldKey].error = !isFieldValid;
      this.fields[fieldKey].help = errorText;
      return isFieldValid;
    },
    isFormValid() {
      let isFormValid = true;
      Object.keys(this.fields).forEach(fieldKey => {
        /**
         * This condition is to bypass validation check for form fields when this component is rendered for Add Subcontract
         */
        if (this.isModeSubcontract && ["typeOfContract", "yourRole"].includes(fieldKey)) return;
        const fieldValue = this.fields[fieldKey].attrs.value;
        const fieldType = this.fields[fieldKey].attrs.type || "input";
        let isFieldValid = this.isFieldValid(fieldValue, fieldKey, fieldType);
        isFormValid = isFormValid && isFieldValid;
      });
      /**
       * When this component is rendered for Add subcontract, no files are mandatory
       */
      if (this.hasFiles && this.isModeNormal) {
        isFormValid = isFormValid && Boolean(this.files.privacy_contract.length);
      }
      return isFormValid;
    },
    async submitForm() {
      if (this.isFormValid()) {
        await this.handleRequest();
      }
    },
    fileToData(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    async prepareFilesArray() {
      return new Promise(resolve => {
        const filesArrWithBase64 = [];
        const totalFiles = Object.keys(this.files).reduce((pVal, fileKey) => {
          return pVal + this.files[fileKey].length;
        }, 0);
        // when no files are chosen, resolve this function with blank data
        if (totalFiles === 0) resolve([]);
        Object.keys(this.files).forEach(fileKey => {
          if (this.files[fileKey].length) {
            this.files[fileKey].forEach(async file => {
              let contentBuffer = await this.fileToData(file);
              filesArrWithBase64.push({
                file_name: file.name,
                file_type: fileKey,
                file: contentBuffer,
              });
              if (filesArrWithBase64.length === totalFiles) {
                resolve(filesArrWithBase64);
              }
            });
          }
        });
      });
    },
    async buildFormData() {
      let formData = {};
      const contractType = this.fields.typeOfContract.attrs.value;
      /**
       * For subcontract, we send in fixed details for contract creation
       */
      if (this.isModeSubcontract) {
        formData["workflow_type"] = "import_contract";
        formData["contract_type"] = "dpa";
        formData = {
          ...formData,
          ...this.subcontractDetails,
        };
      } else {
        /**
         * For normal contract creation, we send in required fields
         */
        formData["workflow_type"] = contractRouteMap[this.$route.name];
        formData["contract_type"] = contractType;
        formData["customer_role"] = this.fields.yourRole.attrs.value;
      }
      formData["partners"] = [
        {
          name: this.fields.partnerName.attrs.value,
          address: {
            city: this.fields.city.attrs.value,
            country: this.fields.country.attrs.value,
            postal_code: this.fields.zipCode.attrs.value,
            street: this.fields.street.attrs.value,
            street_no: this.fields.houseNumber.attrs.value,
          },
          contacts: [
            {
              gender: this.fields.salutation.attrs.value,
              first_name: this.fields.firstName.attrs.value,
              last_name: this.fields.lastName.attrs.value,
              email: this.fields.email.attrs.value,
              country_code: this.fields.country_code.attrs.value,
              phone: this.fields.phone.attrs.value,
            },
          ],
        },
      ];
      if (this.hasFiles) {
        formData["files"] = await this.prepareFilesArray(contractType);
      }
      return formData;
    },
    async handleRequest() {
      const { id } = this.$route.params;
      this.isCreating = true;
      try {
        const formBody = await this.buildFormData();
        const endpoint = `/api1/v2/customers/${id}/privacy_contracts`;
        const { data } = await this.$http({
          url: endpoint,
          method: "POST",
          data: formBody,
        });
        this.$dgNotification.show({
          variant: "bold",
          type: "success",
          title: this.$t("ppm.form.successMsg"),
        });
        this.goBack(data.data);
      } catch (error) {
        const { data = [] } = error;
        if (Array.isArray(data) && data.length) {
          this.$dgNotification.show({
            variant: "bold",
            type: "error",
            title: data[0].detail,
          });
        } else {
          this.$dgNotification.show({
            variant: "bold",
            type: "error",
            title: this.$t("ppm.form.errMsg"),
          });
        }
      }
      this.isCreating = false;
    },
    goBack(newData) {
      /**
       * If we use this component for adding subcontract
       * Send the newly created subcontract data to add new node in the tree
       * otherwise redirect to Dashboard for other cases
       */
      if (this.isModeSubcontract) {
        this.$emit("on-create-success", newData);
      } else {
        this.$router.push({ name: "PPMDashboard" });
      }
    },
  },
};
</script>

<style lang="scss">
.dg-ppm__form {
  background-color: #fff;
  padding: 24px;

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  &__sub-title {
    margin-top: 32px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }

  &__break {
    background-color: #c9d2d7;

    &.top {
      margin-top: 16px;
      margin-bottom: 24px;
    }

    &.bottom {
      margin-top: 32px;
      margin-bottom: 24px;
    }
  }

  &__group {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  &__input {
    width: 100%;

    &.half {
      width: 48%;
    }

    &.country_code {
      width: 160px;
      margin-right: 24px;
    }

    &.house-number {
      width: 160px;
      margin-left: 24px;
    }

    &.zip-code {
      width: 200px;
      margin-right: 24px;
    }
  }

  &__submit {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
